<template>
	<div class="rworkHome2">
		<div class="topClass">
			<el-row :gutter="20">
				<el-col :span='8'>
					<div class='itWarp wtBg'>
						<div class="_tpBox">
							<div class="_fx w45">
								<div class="_name">
									<img src="@/assets/images/Home/Group3088@2x.png" alt="" /> 设备总数
								</div>
								<div class="numb">
									<ICountUp
					                    :endVal="deviceObj.total"
					                    :options="options"
					                  />
									<!--{{deviceObj.total}}-->
								</div>
							</div>
							<!--<div class="_fx w55">
		  						<ul class="liwarp">
			  						<li v-for="(it,dex) in 3" :key='dex'>一期设备 <span>1000 <i class="el-icon-arrow-right"></i></span> </li>
			  					</ul>
		  					</div>-->
						</div>
						<div class="_btBox">
							<div class="tpMc">设备变更记录 <span class="pBtn" @click="toDevice">点击查看详情 <i class="el-icon-arrow-right"></i></span></div>
							<ul class="ulWarp">
								<li v-for="(it,dex) in deviceObj.rows" :key='dex'>
									<span class="_bh tBule txNowrap" @click="jumpPageByType(it,1)">{{it.factoryCode}}</span>
									<span class="_mc txNowrap">{{it.deviceName}}</span>
									<span class="_dt txNowrap">{{it.lastUpdateDateStr}}</span>
								</li>
							</ul>
						</div>
					</div>
				</el-col>
				<el-col :span='8'>
					<div class='itWarp wtBg'>
						<div class="_tpBox">
							<div class="_fx w45">
								<div class="_name"><img src="@/assets/images/Home/Group3089@2x.png" alt="" />工单异常</div>
								<div class="numb">
									<ICountUp
					                    :endVal="abOrderObj.orderCountNumber"
					                    :options="options"
					                  />
								</div>
							</div>
							<div class="_fx w55">
								<ul class="liwarp">
									<li v-for="(it,dex) in abOrderObj.statisticsOrderSonVOList" :key='dex'>
										{{it.responsibilityDeptName}} <span>{{it.orderNumber}}</span> 
										<!--<i class="el-icon-arrow-right"></i>-->
									</li>
								</ul>
							</div>
						</div>
						<div class="_btBox">
							<div class="tpMc">工单超时记录 <span class="pBtn" @click='toCsOrder'>点击查看详情 <i class="el-icon-arrow-right"></i></span></div>
							<ul class="ulWarp">
								<li v-for="(it,dex) in abOrderObj.orderVOList" :key='dex'>
									<span class="_bh tRed txNowrap" @click="jumpPageByType(it,2)">{{deadLineRender(it)}}</span>
									<span class="_mc txNowrap">{{it.problemDesc}}</span>
									<span class="_dt txNowrap">{{it.requestDateStr1}}</span>
								</li>
							</ul>
						</div>
					</div>
				</el-col>
				<el-col :span='8'>
					<div class='itWarp wtBg'>
						<div class="_tpBox">
							<div class="_fx w45">
								<div class="_name"><img src="@/assets/images/Home/Group3090@2x.png" alt="" />
									<el-select class='abType' v-model="abnormalVal" placeholder="请选择" size='mini'>
										<el-option label="巡检异常数据" value="1" />
										<el-option label="保养异常数据" value="2" />
									</el-select>
								</div>
								<div class="numb">
									<ICountUp
					                    :endVal="xunJianByObj.overtimeNumber"
					                    :options="options"
					                  />
								</div>
							</div>
							<div class="_fx w55">
								<ul class="liwarp">
									<li v-for="(it,dex) in xunJianByObj.statisticsInspectionSonVOList" :key='dex'>{{it.deptName}} 
										<span>{{it.inspectionNumber}}
											<!--<i class="el-icon-arrow-right"></i>-->
										</span> </li>
								</ul>
							</div>
						</div>
						<div class="_btBox">
							<div class="tpMc">巡检超时记录 <span class="pBtn" @click="toInspeclist">点击查看详情<i class="el-icon-arrow-right"></i></span></div>
							<ul class="ulWarp">
								<li v-for="(it,dex) in xunJianByObj.inspectionJobVOList" :key='dex'>
									<span class="_bh tRed txNowrap" @click="jumpPageByType(it,3)">{{inspectionDiffTime(it)}}</span>
									<span class="_mc txNowrap">{{it.inspectionJobName}}</span>
									<span class="_dt txNowrap">{{it.lastUpdateDate}}</span>
								</li>
							</ul>
						</div>
					</div>
				</el-col>
			</el-row>

		</div>

		<div class="part2">
			<el-row :gutter="20">
				<el-col :span='8'>
					<div class="ctBox1 wtBg">
						<div class="_ctName">
							设备故障统计
							<div class="ckDay">
								<el-select v-model="dayVal" size='mini' style='max-width:140px;' placeholder="请选择" @change='initfultDeviceChart'>
									<el-option label="近一个月" value="30" />
									<el-option label="近一年" value="365" />
								</el-select>
							</div>
						</div>
						<div id="ftDevice" style="height:230px;"></div>

					</div>
					<div class="wtBg">
						<div class="_ctName">故障类型统计</div>
						<div class="orderUl">
							<div v-if="!userAmountList||userAmountList.length<1" class="no-data-box">
								<img src="@/assets/images/Home/no-data.png" style="width: 100%;height: 155px;" />
								<div>暂无数据</div>
							</div>
							<ul>
								<li v-for="(item, index) in userAmountList" :key="index">
									<el-row>
										<el-col :span="8">
											<div style="min-height: 12px;" class="txNowrap">{{item.userName}}</div>
										</el-col>
										<el-col :span="12">
											<el-progress :percentage="item.round" :show-text='false' :stroke-width="14" color="#2A61FF" />
										</el-col>
										<el-col :span="4">
											<div class="txNowrap rtx">{{item.count}}条</div>
										</el-col>
									</el-row>
								</li>
							</ul>
						</div>

					</div>

				</el-col>
				<el-col :span='16'>
					<div class="wtBg listBox">
						<div class="_ctName">设备动态列表</div>
						<div class="tbWarp">
							<TTable ref="YTb1" :border='false' :stripe='false' :tbloading='tbloading' :tableData="tableData" :tableTitle="tableTitle" :maxHeight='530'>
								<template slot='tbCode' slot-scope="s">
									<el-button type="text" @click='toAlarmDetail(s.row)'><span class="tBule">{{s.row.rworkDeviceName}}</span></el-button>
								</template>
								<template slot='statusColor' slot-scope="s">
									<span class="statusSpan" :class='"bgC"+s.row.alarmStatus' >{{s.row.alarmStatusStr}}</span>
								</template>
								
							</TTable>

						</div>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>
<script>
	// 导入主题，引用时在v-chart中增加theme="macarons"
	import "echarts/theme/macarons";
	import * as echarts from "echarts";
	import ECharts from "vue-echarts";
	import pagination from "@/mixins/TTable/pagination";
	import seachAndTable from "@/mixins/TTable/seachAndTable";
	import TSeach from '@/components/YTable/TSeach.vue';
	import TTable from '@/components/YTable/TTable.vue';
	import TPagination from '@/components/YTable/TPagination.vue';
	import { orderCompleteAmount } from "@/api/business/home/tenant/facilityHome";
	import initAlarmlist from "@/mixins/handsRlink.js";
	import { differenceInDays, intervalToDuration, addMinutes } from "date-fns";
	import {getLoopUpItem} from "@/api/business/base/tenant/device";
	import ICountUp from "vue-countup-v2";
	export default {
		mixins: [pagination, seachAndTable, initAlarmlist],
		components: {
			TTable,
			TSeach,
			TPagination,
			ICountUp
		},
		computed: {},
		data() {
			return {
				options: {
			        duration: 0.6,
			        useEasing: true,
			        useGrouping: true,
			        separator: "",
			        decimal: ".",
			        prefix: "",
			        suffix: "",
			        // numerals: ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"],
		      	},
				tableTitle: [{
						name: '设备名称',
						prop: "rworkDeviceName",
						width:180,
						slotName: "tbCode"
					},
					{
						name: '告警时间',
						width:180,
						prop: "alarmTimestampStr"
					},
					{
						name: '告警内容',
						prop: "alarmContent"
					},
					{
						name: '告警级别',
						width:120,
						prop: "alarmLevelStr"
					},
					{
						name: '告警状态',
						width:120,
						prop: "alarmStatusStr",
						slotName:'statusColor'
					},
				],
				userAmountList: [],
				ftDeviChart: null,
				ftClassChart: null,
				dayVal: '365',
				abnormalVal: '1', //异常数据类型 1=巡检 2保养
				xunJianByObj: { //巡检保养的数据
					overtimeNumber: 0,
					inspectionJobVOList: [],
					statisticsInspectionSonVOList: []
				},
				deviceObj: {
					total: 0,
					rows: [],
				},
				abOrderObj: {
					orderCountNumber: 0,
					orderVOList: [],
					statisticsOrderSonVOList: [],
				},
				deviceNamesObj: {}, //设备类型名称集合
			};
		},
		watch: {
			abnormalVal: {
				deep: true,
				handler(val) {
					this.initArrByVals()
				},
			}
		},
		created() {},
		mounted() {
			this.findDeviceType();
			this.$nextTick(() => {
				this.initHtml();
			})

		},
		computed: {},
		methods: {
			findDeviceType() {
		      getLoopUpItem({ classifyCode: "DEVICE_TYPE" }).then((res) => {
		        res.map((it) => (this.deviceNamesObj[it.itemCode] = it.itemName));
		      });
		    },
			async initHtml() {
				this.isIniting = true;
				this.isIniting = false;
				this.initfultDeviceChart();
				this.initGdRanking();
				this.initDeviceData();
				this.initAbOrderData();
				this.initAlarmTbale();
				this.initArrByVals();
			},
			inspectionDiffTime(item) {
				// 任务状态（1：待领取，2：待执行，3：执行中，4：已完成）
				let str = "";
				let i = null;
				if(typeof item.status === "number") {
					switch(item.status) {
						case 2:

							if(item.executedNumber) {
								const firstExecutedOvertime =
									typeof item.firstExecutedOvertime === "number" ?
									item.firstExecutedOvertime :
									0;
								const s = new Date(item.lastUpdateDate);
								const expire = addMinutes(s, firstExecutedOvertime);
								i = {
									start: new Date(expire),
									end: new Date(),
								};
								str += "超时";

							}
							// if (this.checkExpire(item)) {
							// if (typeof item.lastUpdateDate === "number") {

							// }
							// } else {
							//   if (item.endTime) {
							//     i = {
							//       start: new Date(),
							//       end: new Date(item.endTime),
							//     };
							//     str += "剩余";
							//   }
							// }
							break;
						case 3:
							// if (this.checkExpire(item)) {
							//          	completeNumber  执行中超时次数 执行中的工单截止要求完成时间还未提交视为一次超时，之后每过 N 分钟视为一次超时。
							if(item.completeNumber && item.endTime) {
								i = {
									start: new Date(item.endTime),
									end: new Date(),
								};
								str += "超时";
							}
							// } else {
							//   if (item.endTime) {
							//     i = {
							//       start: new Date(),
							//       end: new Date(item.endTime),
							//     };
							//     str += "剩余";
							//   }
							// }
							break;
						case 1:
							// if (this.checkExpire(item)) {
							//          	unclaimedNumber  待领取超时次数 待领取状态下的工单如果未被领取，每  N 分钟视为一次超时。
							if(item.unclaimedNumber && item.creationDate) {
								//待领取状态下的工单如果未被领取，每 N 分钟视为一次超时

								const unclaimedOvertime =
									typeof item.unclaimedOvertime === "number" ?
									item.unclaimedOvertime :
									0;
								const s = new Date(item.creationDate).getTime();
								const expire = addMinutes(s, unclaimedOvertime);
								i = {
									start: new Date(expire),
									end: new Date(),
								};
								str += "超时";
							}
							// } else {
							//   if (item.startTime) {
							//     i = {
							//       start: new Date(item.startTime),
							//       end: new Date(),
							//     };
							//     str += "已生成";
							//   }
							// }
							break;
					}
				}

				if(i) {
					const m = new Date(i.end).getTime - new Date(i.start).getTime();
					if(m < 1) {
						str += "0分";
					} else {
						const d = intervalToDuration(i);
						const days = differenceInDays(i.end, i.start);

						if(days > 0) {
							str += `${days}天`;
						}
						if(d.hours && d.hours > 0) {
							str += `${d.hours}小时`;
						}
						if(d.minutes && d.minutes > 0) {
							str += `${d.minutes}分`;
						}
					}
				}

				return str;
			},
			// 逻辑同小程序
			toIOSDateString(s) {
		      	return s.replace(/-/g, "/") || undefined;
		    },
		    timediff(startDate, endDate) {
		      let date3 = endDate.getTime() - startDate.getTime(); //时间差的毫秒数
		      //计算出相差天数
		      let days = Math.floor(date3 / (24 * 3600 * 1000));
		      //计算出小时数
		      let leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
		      let hours = Math.floor(leave1 / (3600 * 1000));
		      //计算相差分钟数
		      let leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
		      let minutes = Math.floor(leave2 / (60 * 1000));
		      //计算相差秒数
		      let leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
		      let seconds = Math.round(leave3 / 1000);
		      if (
		        (days && days > 0) ||
		        (hours && hours > 0) ||
		        (minutes && minutes > 0)
		      ) {
		        return (
		          (days > 0 ? days + "天" : "") +
		          (hours > 0 ? hours + "小时" : "") +
		          (minutes > 0 ? minutes + "分" : "")
		        );
		      } else {
		        return 0 + "分";
		      }
		    },
			deadLineRender(data) {
				const orderStatus = data.orderStatus;
				// 1: 待领取、2：待执行、3：执行中
				switch(orderStatus) {
					case 1:
						if(!data.creationDateStr ||
							data.unclaimedNumber == null ||
							data.unclaimedOvertime == null
						) {
							return "";
						}
						if(data.unclaimedNumber > 0) {
							// 超时
							const examine = data.orderDynamicList[0].creationDateStr;
							const formatExamine = new Date(this.toIOSDateString(examine));
							const examineMinute = new Date(
								formatExamine.setMinutes(
									formatExamine.getMinutes() + data.unclaimedOvertime
								)
							);
							return "超时" + this.timediff(examineMinute, new Date());
						} else {
							// 未超时
							return(
								"已生成" +
								this.timediff(
									new Date(this.toIOSDateString(data.creationDateStr)),
									new Date()
								)
							);
						}
						break;
					case 2:
						if(!data.requestDateStr ||
							!data.orderDynamicList[0].creationDateStr ||
							data.executedNumber == null ||
							data.firstExecutedOvertime == null
						) {
							return "";
						}
						if(data.executedNumber > 0) {
							// 计算超时时间-ych
							let receiveMinute;
							let that = this;

							function getTimeByStatus(statusKey) {
								data.orderDynamicList.map((it) => {
									if(it.operationType === statusKey) {
										if(data.executedNumber) {
											const formatReceive = new Date(
												that.toIOSDateString(it.creationDateStr)
											);
											receiveMinute = new Date(
												formatReceive.setMinutes(
													formatReceive.getMinutes() + data.firstExecutedOvertime
												)
											);
										}
									}
								});
							}
							let findArr = ["receive", "report_audit_ok", "create"];
							findArr.map((its) => {
								if(!receiveMinute) getTimeByStatus(its);
							});
							if(!receiveMinute) return "";
							return "超时" + this.timediff(receiveMinute, new Date());
						} else {
							return(
								"剩余" +
								this.timediff(
									new Date(),
									new Date(this.toIOSDateString(data.requestDateStr))
								)
							);
						}
						break;
					case 3:
						if(!data.requestDateStr) {
							return "";
						}
						if(
							new Date().getTime() <
							new Date(this.toIOSDateString(data.requestDateStr)).getTime()
						) {
							// 未超时
							return(
								"剩余" +
								this.timediff(
									new Date(),
									new Date(this.toIOSDateString(data.requestDateStr))
								)
							);
						} else {
							// 超时
							return(
								"超时" +
								this.timediff(
									new Date(this.toIOSDateString(data.requestDateStr)),
									new Date()
								)
							);
						}
						break;
					case 7:
						if(!data.requestDateStr) {
							return "";
						}
						if(
							new Date().getTime() <
							new Date(this.toIOSDateString(data.requestDateStr)).getTime()
						) {
							// 未超时
							return(
								"剩余" +
								this.timediff(
									new Date(),
									new Date(this.toIOSDateString(data.requestDateStr))
								)
							);
						} else {
							// 超时
							return(
								"超时" +
								this.timediff(
									new Date(this.toIOSDateString(data.requestDateStr)),
									new Date()
								)
							);
						}
				}
			},
			jumpPageByType(row, type) {
				let params = {};
				if(type == '1') {
					params = {
						path: '/rwork/deviceLedger/ledgerDetail',
						query: {
							deviceId:row.deviceId,
		      				factoryCode:row.factoryCode,
		      				deviceNamesObj:this.deviceNamesObj,
		      				productKey:row.productKey,
		      				rlinkDeviceName:row.rlinkDeviceName,
						},
					}
				}
				if(type == '2') {
					params = {
						path: '/iot/orderQuery/detail',
						query: {
							orderId: row.orderId
						},
					}
				}
				if(type == '3') {
					params = {
						path: '/tenant/inspection/inspectionSearch/detail',
						query: {
							inspectionJobId: row.inspectionJobId
						},
					}
				}
				this.$router.push(params);

			},
			toAlarmDetail(row) {
				let params = {
					path: "/iot/alarmRecords/detail",
					query: {
						rowId: row.alarmUUID
					},
				};
				this.$router.push(params);

			},
			async initArrByVals() {
				//切换异常数据类型 请求数据
				if(!this.abnormalVal) return;
				let res = await this.ApiHttp('/inspection/tenant/inspection/job/statisticsOverTimeInspection?planType=' + this.abnormalVal);
				if(res.inspectionJobVOList) {
					res.inspectionJobVOList.map(it => {
						it.lastUpdateDate = this.common.getTimeMmss(it.lastUpdateDate, 'day');
					})
				}
				this.xunJianByObj = { ...res
				};
			},
			async initAlarmTbale() {
				this.tbloading=true;
				let params = {
					current: 1,
					rowCount: 8
				};
				let response = await this.initAlarmlist(params);
				console.log('response', response);
				this.tableData = response.rows;
				this.tbloading=false;
			},

			async initDeviceData() {
				//设备数据
				let params = {
					current: 1,
					rowCount: 3
				};
				let res = await this.ApiHttp('/base/tenant/device/page', params, 'get');
				console.log('设备数据', res);
				res.rows.map(it => {
					it.lastUpdateDateStr = this.common.getTimeMmss(it.lastUpdateDate, 'day');
				})
				this.deviceObj = { ...res
				};
			},
			async initAbOrderData() { //工单异常数据
				let res = await this.ApiHttp('/order/tenant/order/statisticsOrder');
				console.log('45454', res)
				res.orderVOList.map(it => {
					it.requestDateStr1 = this.common.getTimeMmss(it.requestDateStr, 'day');
				})
				this.abOrderObj = { ...res
				};
			},
			async initInspecDatas() {
				//获取巡检异常数据。
				let params = {};
				let res = await this.ApiHttp('/organization/tenant/dept/list/tree', params, 'GET');
			},

			toDevice() {
				let params = {
					path: "/tenant/base/device/manager",
					//			        query: {isOverTime:'Y'},
				};
				this.$router.push(params);
			},
			toInspeclist() {
				let params = {
					path: "/tenant/inspection/inspectionSearch/list",
					query: {
						isOverTime: 'Y'
					},
				};
				this.$router.push(params);

			},
			toCsOrder() {
				let params = {
					path: "/tenant/order/orderQuery/order/all",
					query: {
						isOverTime: 'Y'
					},
				};
				this.$router.push(params);
			},
			async initGdRanking() { //工单完成量排行
				this.userAmountList = [];
				this.ApiHttp('/order/tenant/order/statisticsFaultType').then((res) => {
					//		      		console.log('工单完成量排行',res)
					res.map((v) => {
						let round = Math.round((v.faultTypeNumber / 10) * 10000) / 100.0;
						let userName = v.faultTypeName;
						let count = v.faultTypeNumber;
						this.userAmountList.push({
							round: round,
							userName: userName,
							count: count,
						});
					});
				})
			},
			async initfultDeviceChart() {
				//设备类型工单统计
				let params = {
					day: this.dayVal
				}
				let tbRes = await this.ApiHttp('/order/tenant/order/statisticsDeviceFault', params) //查询设备填报趋势
				if(!tbRes) return;
				console.log('12315', tbRes)
				let xArr = [],
					yArr1 = [],
					yArr2 = [];
				for(let i = 0; i < tbRes.length; i++) {
					let tbItem1 = tbRes[i];
					xArr.push(tbItem1.deviceName);
					yArr1.push(tbItem1.faultNumber);
				}
				let options = {
					grid: {
						left: "32px",
						right: "10px",
						bottom: "55px",
						top: "20px",
					},
					tooltip: this.sameTooltip(),
					xAxis: {
						type: 'category',
						show: true,
						axisLabel: {
							color: "#8190AC",
							interval: 0,
							formatter: function(val) {
								return val.trim().split('').join('\n')
							}
						},
						axisLine: {
							lineStyle: {
								color: "#E6E9EE",
							},
						},
						data: xArr, //['A', 'B','C', 'D', 'E']
					},
					yAxis: {
						type: 'value',
						splitNumber: 3,
						splitLine: {
							show: true,
							lineStyle: {
								color: "#E6E9EE",
							},
						},
						axisLine: {
							lineStyle: {
								color: "#E6E9EE",
							},
						},
						axisLabel: {
							color: "#8190AC",
						},
					},
					series: [{
						name: '统计数',
						type: 'bar',
						data: yArr1, //[19, 23, 31, 12, 3, 7],
						barWidth: '9px',
						itemStyle: {
							color: '#3063FC',
							//					      		barBorderRadius: [10, 10, 0, 0],
						},
					}, ]

				}
				var chartDom = document.getElementById("ftDevice");
				this.ftDeviChart = echarts.init(chartDom);
				this.ftDeviChart.setOption(options);

			},
			sameTooltip() {
				return {
					show: true,
					trigger: "axis",
					padding: 0,
					borderWidth: 1,
					borderColor: '#F0F7FC',
					backgroundColor: "#00000000",
					axisPointer: {
						type: "line",
						lineStyle: {
							width: 1,
							type: "dotted",
							opacity: 0.5,
							color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
									offset: 0,
									color: "#40DEC2"
								},
								{
									offset: 1,
									color: "#40DEC2"
								},
							]),
						},
					},
					alwaysShowContent: false,
					formatter(params) {
						let domStr = "<div class='tips-main'>";
						params.forEach((item) => {
							const {
								name,
								value
							} = item;
							domStr += `<div class="tips-container"><span class="point" style='background:${item.color}'></span> ${name}: ${value}</div>`;
						});
						domStr += "</div>";
						return domStr;
					},
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.rworkHome2 {
		height: calc(100vh - 60px);
		overflow-y: auto;
		padding: 18px;
		.statusSpan{
			display: inline-block;
			width: 44px;
			height: 24px;
			background: #F0F9EB;
			border-radius: 2px;
			border: 1px solid #E1F3D8;
			color: #67C23A;
			text-align: center;
		}
		.statusSpan.bgCalarm{
			background: #fef0f0;
		    border-color: #fde2e2;
		    color: #f56c6c;
		}
		.tBule {
			color: #4374FA;
		}
		.tRed {
			color: #F55F45;
		}
		.wtBg {
			border-radius: 6px;
			background: #FFFFFF;
		}
		.listBox {
			height: 594px;
		}
		.part2 {
			margin: 20px 0;
			._ctName {
				color: #303133;
				font-weight: bold;
				padding-bottom: 16px;
			}
			.wtBg {
				padding: 20px;
			}
			.ctBox1 {
				margin-bottom: 20px;
				._ctName {
					position: relative;
					.ckDay {
						position: absolute;
						right: 0;
						width: 140px;
						height: 25px;
						top: -10px;
					}
				}
			}
			.orderUl {
				height: 200px;
				overflow-y: auto;
				font-size: 12px;
				li {
					padding: 6px 0;
					color: #77838F;
				}
				.rtx {
					padding-left: 10px;
					text-align: right;
				}
				.no-data-box {
					width: 280px;
					margin: 0 auto;
					color: #8190AC;
					text-align: center;
				}
			}
		}
		.topClass {
			.itWarp {
				padding: 15px 14px;
				._tpBox {
					padding-bottom: 18px;
					height: 120px;
					border-bottom: 1px solid #DCDFE5;
					display: flex;
					.w45 {
						width: 53%;
						._name {
							color: #303133;
							line-height: 24px;
							font-weight: bold;
							img {
								width: 20px;
								height: 20px;
								position: relative;
								top: 4px;
								margin-right:6px;
							}
						}
						.abType {
							width: calc(100% - 32px);
							max-width: 152px;
							display: inline-block;
							vertical-align: middle;
							height: 32px;
						}
						.numb {
							color: #152C5B;
							font-size: 36px;
							padding-top: 28px;
						}
					}
					.w55 {
						width: 52%;
						.liwarp {
							padding-right: 10px;
							font-size: 12px;
							color: #909399;
							li {
								padding: 10px 0;
								position: relative;
								span {
									color: #303133;
									position: absolute;
									right: 0;
									font-weight: bold;
								}
							}
						}
					}
				}
				._btBox {
					font-size: 12px;
					min-height: 142px;
					padding-top: 16px;
					.tpMc {
						color: #909399;
						padding-bottom: 18px;
						position: relative;
						.pBtn {
							position: absolute;
							right: 0;
							color: #4374FA;
							cursor: pointer;
						}
					}
					.ulWarp {
						li {
							display: flex;
							font-size: 12px;
							padding: 10px 0;
							._bh {
								width: 30%;
								cursor: pointer;
							}
							._mc {
								color: #606266;
								width: 40%;
							}
							._dt {
								color: #909399;
								text-align: right;
								width: 30%;
							}
						}
					}
				}
			}
		}
	}
</style>
<style lang="scss">
	.tbWarp {
		tbody {
			.cell {
				color: #000000;
				font-size: 12px;
				.tBule{
					font-size: 12px !important;
				}
			}
			.el-table__row:nth-child(even) {
				.el-table__cell {
					background: #F9FBFC;
				}
			}
		}
		.el-table::before {
			height: 0;
		}
		.el-table th.el-table__cell.is-leaf,
		.el-table td.el-table__cell {
			border-bottom: none !important;
		}
		thead {
			.disabledCheck {
				border-radius: 10px 0 0 10px;
			}
			.el-table_1_column_5 {
				border-radius: 0 10px 10px 0 !important;
			}
		}
	}
	
	.tips-main {
		//折线图点标记
		width: auto;
		min-width: 100px;
		line-height: 24px;
		text-align: center;
		color: #152C5B;
		font-weight: bold;
		border-radius: 4px;
		font-size: 12px;
		padding: 6px 12px;
		background-color: white;
		.point {
			display: inline-block;
			width: 8px;
			height: 8px;
			border-radius: 50%;
		}
	}
	
	.tips-main.pieTips {
		width: auto;
		text-align: left;
		padding-left: 12px;
	}
</style>