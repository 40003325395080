var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "rworkHome2" }, [
    _c(
      "div",
      { staticClass: "topClass" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "itWarp wtBg" }, [
                _c("div", { staticClass: "_tpBox" }, [
                  _c("div", { staticClass: "_fx w45" }, [
                    _c("div", { staticClass: "_name" }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/Home/Group3088@2x.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" 设备总数\n\t\t\t\t\t\t\t"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "numb" },
                      [
                        _c("ICountUp", {
                          attrs: {
                            endVal: _vm.deviceObj.total,
                            options: _vm.options,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "_btBox" }, [
                  _c("div", { staticClass: "tpMc" }, [
                    _vm._v("设备变更记录 "),
                    _c(
                      "span",
                      { staticClass: "pBtn", on: { click: _vm.toDevice } },
                      [
                        _vm._v("点击查看详情 "),
                        _c("i", { staticClass: "el-icon-arrow-right" }),
                      ]
                    ),
                  ]),
                  _c(
                    "ul",
                    { staticClass: "ulWarp" },
                    _vm._l(_vm.deviceObj.rows, function (it, dex) {
                      return _c("li", { key: dex }, [
                        _c(
                          "span",
                          {
                            staticClass: "_bh tBule txNowrap",
                            on: {
                              click: function ($event) {
                                return _vm.jumpPageByType(it, 1)
                              },
                            },
                          },
                          [_vm._v(_vm._s(it.factoryCode))]
                        ),
                        _c("span", { staticClass: "_mc txNowrap" }, [
                          _vm._v(_vm._s(it.deviceName)),
                        ]),
                        _c("span", { staticClass: "_dt txNowrap" }, [
                          _vm._v(_vm._s(it.lastUpdateDateStr)),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
            _c("el-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "itWarp wtBg" }, [
                _c("div", { staticClass: "_tpBox" }, [
                  _c("div", { staticClass: "_fx w45" }, [
                    _c("div", { staticClass: "_name" }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/Home/Group3089@2x.png"),
                          alt: "",
                        },
                      }),
                      _vm._v("工单异常"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "numb" },
                      [
                        _c("ICountUp", {
                          attrs: {
                            endVal: _vm.abOrderObj.orderCountNumber,
                            options: _vm.options,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "_fx w55" }, [
                    _c(
                      "ul",
                      { staticClass: "liwarp" },
                      _vm._l(
                        _vm.abOrderObj.statisticsOrderSonVOList,
                        function (it, dex) {
                          return _c("li", { key: dex }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\t" +
                                _vm._s(it.responsibilityDeptName) +
                                " "
                            ),
                            _c("span", [_vm._v(_vm._s(it.orderNumber))]),
                          ])
                        }
                      ),
                      0
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "_btBox" }, [
                  _c("div", { staticClass: "tpMc" }, [
                    _vm._v("工单超时记录 "),
                    _c(
                      "span",
                      { staticClass: "pBtn", on: { click: _vm.toCsOrder } },
                      [
                        _vm._v("点击查看详情 "),
                        _c("i", { staticClass: "el-icon-arrow-right" }),
                      ]
                    ),
                  ]),
                  _c(
                    "ul",
                    { staticClass: "ulWarp" },
                    _vm._l(_vm.abOrderObj.orderVOList, function (it, dex) {
                      return _c("li", { key: dex }, [
                        _c(
                          "span",
                          {
                            staticClass: "_bh tRed txNowrap",
                            on: {
                              click: function ($event) {
                                return _vm.jumpPageByType(it, 2)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.deadLineRender(it)))]
                        ),
                        _c("span", { staticClass: "_mc txNowrap" }, [
                          _vm._v(_vm._s(it.problemDesc)),
                        ]),
                        _c("span", { staticClass: "_dt txNowrap" }, [
                          _vm._v(_vm._s(it.requestDateStr1)),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
            _c("el-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "itWarp wtBg" }, [
                _c("div", { staticClass: "_tpBox" }, [
                  _c("div", { staticClass: "_fx w45" }, [
                    _c(
                      "div",
                      { staticClass: "_name" },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/Home/Group3090@2x.png"),
                            alt: "",
                          },
                        }),
                        _c(
                          "el-select",
                          {
                            staticClass: "abType",
                            attrs: { placeholder: "请选择", size: "mini" },
                            model: {
                              value: _vm.abnormalVal,
                              callback: function ($$v) {
                                _vm.abnormalVal = $$v
                              },
                              expression: "abnormalVal",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "巡检异常数据", value: "1" },
                            }),
                            _c("el-option", {
                              attrs: { label: "保养异常数据", value: "2" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "numb" },
                      [
                        _c("ICountUp", {
                          attrs: {
                            endVal: _vm.xunJianByObj.overtimeNumber,
                            options: _vm.options,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "_fx w55" }, [
                    _c(
                      "ul",
                      { staticClass: "liwarp" },
                      _vm._l(
                        _vm.xunJianByObj.statisticsInspectionSonVOList,
                        function (it, dex) {
                          return _c("li", { key: dex }, [
                            _vm._v(
                              _vm._s(it.deptName) + " \n\t\t\t\t\t\t\t\t\t"
                            ),
                            _c("span", [
                              _vm._v(
                                _vm._s(it.inspectionNumber) +
                                  "\n\t\t\t\t\t\t\t\t\t\t"
                              ),
                            ]),
                          ])
                        }
                      ),
                      0
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "_btBox" }, [
                  _c("div", { staticClass: "tpMc" }, [
                    _vm._v("巡检超时记录 "),
                    _c(
                      "span",
                      { staticClass: "pBtn", on: { click: _vm.toInspeclist } },
                      [
                        _vm._v("点击查看详情"),
                        _c("i", { staticClass: "el-icon-arrow-right" }),
                      ]
                    ),
                  ]),
                  _c(
                    "ul",
                    { staticClass: "ulWarp" },
                    _vm._l(
                      _vm.xunJianByObj.inspectionJobVOList,
                      function (it, dex) {
                        return _c("li", { key: dex }, [
                          _c(
                            "span",
                            {
                              staticClass: "_bh tRed txNowrap",
                              on: {
                                click: function ($event) {
                                  return _vm.jumpPageByType(it, 3)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.inspectionDiffTime(it)))]
                          ),
                          _c("span", { staticClass: "_mc txNowrap" }, [
                            _vm._v(_vm._s(it.inspectionJobName)),
                          ]),
                          _c("span", { staticClass: "_dt txNowrap" }, [
                            _vm._v(_vm._s(it.lastUpdateDate)),
                          ]),
                        ])
                      }
                    ),
                    0
                  ),
                ]),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "part2" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "ctBox1 wtBg" }, [
                _c("div", { staticClass: "_ctName" }, [
                  _vm._v("\n\t\t\t\t\t\t设备故障统计\n\t\t\t\t\t\t"),
                  _c(
                    "div",
                    { staticClass: "ckDay" },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { "max-width": "140px" },
                          attrs: { size: "mini", placeholder: "请选择" },
                          on: { change: _vm.initfultDeviceChart },
                          model: {
                            value: _vm.dayVal,
                            callback: function ($$v) {
                              _vm.dayVal = $$v
                            },
                            expression: "dayVal",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "近一个月", value: "30" },
                          }),
                          _c("el-option", {
                            attrs: { label: "近一年", value: "365" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", {
                  staticStyle: { height: "230px" },
                  attrs: { id: "ftDevice" },
                }),
              ]),
              _c("div", { staticClass: "wtBg" }, [
                _c("div", { staticClass: "_ctName" }, [_vm._v("故障类型统计")]),
                _c("div", { staticClass: "orderUl" }, [
                  !_vm.userAmountList || _vm.userAmountList.length < 1
                    ? _c("div", { staticClass: "no-data-box" }, [
                        _c("img", {
                          staticStyle: { width: "100%", height: "155px" },
                          attrs: {
                            src: require("@/assets/images/Home/no-data.png"),
                          },
                        }),
                        _c("div", [_vm._v("暂无数据")]),
                      ])
                    : _vm._e(),
                  _c(
                    "ul",
                    _vm._l(_vm.userAmountList, function (item, index) {
                      return _c(
                        "li",
                        { key: index },
                        [
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 8 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "txNowrap",
                                    staticStyle: { "min-height": "12px" },
                                  },
                                  [_vm._v(_vm._s(item.userName))]
                                ),
                              ]),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c("el-progress", {
                                    attrs: {
                                      percentage: item.round,
                                      "show-text": false,
                                      "stroke-width": 14,
                                      color: "#2A61FF",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("el-col", { attrs: { span: 4 } }, [
                                _c("div", { staticClass: "txNowrap rtx" }, [
                                  _vm._v(_vm._s(item.count) + "条"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
            _c("el-col", { attrs: { span: 16 } }, [
              _c("div", { staticClass: "wtBg listBox" }, [
                _c("div", { staticClass: "_ctName" }, [_vm._v("设备动态列表")]),
                _c(
                  "div",
                  { staticClass: "tbWarp" },
                  [
                    _c("TTable", {
                      ref: "YTb1",
                      attrs: {
                        border: false,
                        stripe: false,
                        tbloading: _vm.tbloading,
                        tableData: _vm.tableData,
                        tableTitle: _vm.tableTitle,
                        maxHeight: 530,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "tbCode",
                          fn: function (s) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toAlarmDetail(s.row)
                                    },
                                  },
                                },
                                [
                                  _c("span", { staticClass: "tBule" }, [
                                    _vm._v(_vm._s(s.row.rworkDeviceName)),
                                  ]),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: "statusColor",
                          fn: function (s) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "statusSpan",
                                  class: "bgC" + s.row.alarmStatus,
                                },
                                [_vm._v(_vm._s(s.row.alarmStatusStr))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }